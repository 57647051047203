<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/aboutBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{path:switchList.link,query:{index:0}}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <div class="WapBoxPadding richText" v-show="activeIndex==0" v-html="overview">
          </div>
          <!--企业文化-->
          <div class="WapBoxPadding row" v-show="activeIndex==1">
            <div class="cultureList row col-12 col-lg-12">
              <div class="cultureCoat col-6" v-for="item in cultureList">
                <div class="culture" >
                  <div class="cultureTop">
                    <div class="cultureTopIcoCoat">
                      <img class="cultureTopIco" :src="item.icon"/>
                    </div>
                    <div class="cultureTopInfo">
                      <p class="cultureTopTitle">{{item.title}}</p>
                      <p class="cultureTopSubtitle">{{item.en_title}}</p>
                    </div>
                  </div>
                  <p class="cultureInfo">{{item.description}}</p>
                </div>
              </div>
            </div>
            <div class="col-12 richText" v-if="cultureRichText" v-html="cultureRichText">
<!--              <img class="cultureImg" src="~assets/images/img/about2.jpg"/>-->
            </div>
          </div>
          <!--荣誉证书-->
          <div class="WapBoxPadding" v-show="activeIndex==2">
            <div class="honor"  v-if="quanzhouHonor && quanzhouHonor.length>0">
              <p class="honorTitle">泉州天润供应链管理有限公司</p>
              <div class="honorList row">
               <div class="honorItemCoat col-lg-4 col-6" v-for="item in quanzhouHonor">
                 <div class="honorItem" >
                   <div class="honorItemImgCoat">
                     <img class="honorItemImg" :src="item.thumb" />
                   </div>
                   <p class="honorItemTitle">{{item.title}}</p>
                 </div>
               </div>
              </div>
            </div>
            <div class="honor"  v-if="xiamenHonor && xiamenHonor.length>0">
              <p class="honorTitle">厦门天润恒丰供应链有限公司</p>
              <div class="honorList row">
               <div class="honorItemCoat col-lg-4 col-6" v-for="item in xiamenHonor">
                 <div class="honorItem" >
                   <div class="honorItemImgCoat">
                     <img class="honorItemImg" :src="'http://dr.37xinxi.cn/'+item.thumb" />
                   </div>
                   <p class="honorItemTitle">{{item.title}}</p>
                 </div>
               </div>
              </div>
            </div>

          </div>
          <!--内容块 END-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import {getAbout} from 'network/about';
  export default {
    name: "About",
    components: {SwitchBar},
    data() {
      return {
        //概况
        overview:null,
        //当前活跃的块下标
        activeIndex: 0,
        //企业文化列表
        cultureList:[],
        cultureRichText:null,
        //荣誉证书列表
        quanzhouHonor:[],
        xiamenHonor:[],
      }
    },
    methods: {
      switchItem(index) {
        if (index != this.$route.query.index) {
          this.$router.push({
            path: this.$route.path,
            query: {
              index: index
            }
          })
        }
      },
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index
        return this.$store.state.navInfo[0];
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE
      },
    },
    created() {
      this.activeIndex = this.$route.query.index;
      getAbout(1).then(res=>{
        this.overview=res.data.profile.description;
      });  getAbout(2).then(res=>{
        this.cultureList=res.data.cuiture.article;
        this.cultureRichText=res.data.cuiture.description;
        // console.log(this.cultureRichText);
      });
      getAbout(3).then(res=>{
       /*
       * quanzhouHonor:[],
        xiamenHonor:[],
       * */
       this.quanzhouHonor=res.data.honor_images_quanzhou;
       this.xiamenHonor=res.data.honor_images_xiamen;
        console.log(res.data.honor_images_xiamen);
      });


    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
      }
    }
  }
</script>
<style>
  .richText img {
    width: 100%;
    height: auto;
    margin-bottom: .4rem;
  }

  .richText p {
    font-size: .35rem;
    color: #a6a6a6;
    margin-bottom: .4rem;
  }
</style>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }
    .WapBoxPadding {
      margin: 0 3%;
    }
 /*   .cultureTopInfo{
      width:4.9rem;
    }*/
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }
  /*  .cultureTopInfo{
      width:2.1rem;
    }*/
    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .honor{
    margin-bottom: .6rem;
  }
.honorTitle{
  padding-left: .6rem;
  border-left: 4px solid var(--red);
  font-size: .5rem;
  color: #4c4c4c;
  margin-bottom: 1rem;
}
.honorList{
  display: flex;
  flex-wrap: wrap;
}
.honorItemCoat{
  padding-right: .8rem;
}
.honorItem{
  margin-bottom: .3rem;
}
.honorItemImgCoat{
  width: 100%;
  height:4.6rem;
  display: flex;
  justify-content: center;
  border: 1px solid #e5e5e5;
  margin-bottom: .2rem;
}
.honorItemImg{
  max-width: 100%;
  height: 100%;
}
.honorItemTitle{
  text-align: center;
  padding: .3rem;
  font-size: .4rem;
}




  .cultureList{
    display:flex;
    height: auto;
    /*padding-right: .6rem;*/
    margin-bottom: .6rem;
  }
  .cultureCoat{
    margin-bottom: .3rem;

  }
  .cultureCoat:nth-child(odd){
    padding: 0 .15rem 0 0;
    text-align: start;
  }
  .cultureCoat:nth-child(even){
    padding: 0 0 0 .15rem;
    text-align: start;
  }

  .culture{
    border: 1px solid #e2e2e2;
    padding:.3rem;
    height: 100%;
  }
  .cultureTop{
    display:flex;
    align-items: flex-start;
    margin-bottom: .6rem;
  }
  .cultureTopIcoCoat{
    /*padding: ;*/
    /*border: 1px solid #e2e2e2;*/
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;
    height: 100%;
  }
  .cultureTopIco,.activeCultureTopIco{
    width: 1.15rem;
    height: .925rem;
  }
  .cultureTopInfo{
    margin-right: .7rem;
  }
  .cultureInfo{
    flex: 1;
    /*text-align: center;*/
    font-size: .4rem;
    color:#737373;
  }
  .cultureTopTitle{
    font-size: .35rem;
    color: var(--red);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 1; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  }
  .cultureTopSubtitle{
    font-size: .3rem;
    color:var(--red);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 1; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  }
  .cultureImg{
    width: 100%;
    height:auto;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .about {
    padding: 1.65rem var(--switchMarginX);
    margin-bottom: .6rem;
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>